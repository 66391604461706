<template>
  <div class="form-box">
    <div class="form-title">个人中心</div>
    <el-form ref="form" :model="form" label-width="80px" class="form-content">
      <el-form-item label="用户名">
        <span v-if="!showEditLoginNameStatus">
          <span style="width: 20vw; display:inline-block">{{form.loginName}}</span>
        </span>
      </el-form-item>

      <el-form-item label="密码">
        <span v-if="!showEditPasswordStatus">
          <span  style="width: 20vw; display:inline-block">******</span><i class="el-icon-edit" @click="openUserInfoModal('password')"></i>
        </span>
      </el-form-item>

      <el-form-item label="姓名">
        <span v-if="!showEditUserNameStatus">
          <span  style="width: 20vw; display:inline-block">{{form.userName}}</span><i class="el-icon-edit" @click="editUserInfo('userName')"></i>
        </span>
        <span v-else>
             <el-input clearable v-model="form.userName"></el-input>
          <span class="save-input-suffix" @click="saveOnClick('userName')">保存</span>
          <span class="cancel-input-suffix" @click="cancelOnClick('userName')">取消</span>
        </span>
      </el-form-item>

      <el-form-item label="所属机构" class="form-input-border">{{form.agencyName}}</el-form-item>
      <el-form-item label="角色">{{form.roleName}}</el-form-item>

      <el-form-item label="手机号">
        <span>
          <span  style="width: 20vw; display:inline-block">{{form.phoneNumber}}</span>
          <i class="el-icon-edit" @click="openUserInfoModal('phoneNumber')"></i>
        </span>
      </el-form-item>

      <el-form-item label="邮箱">
        <span v-if="!showEditEmailStatus">
          <span  style="width: 20vw; display:inline-block">{{form.email}}</span>
          <i class="el-icon-edit" @click="openUserInfoModal('email')"></i>
        </span>
        <span v-else>
             <el-input clearable v-model="form.email"></el-input>
          <span class="save-input-suffix" @click="saveOnClick('email')">保存</span>
          <span class="cancel-input-suffix" @click="cancelOnClick('email')">取消</span>
        </span>
      </el-form-item>

      <el-form-item label="性别">
        <span v-if="!showEditSexStatus">
          <span  style="width: 20vw; display:inline-block">{{form.sex}}</span><i class="el-icon-edit" @click="editUserInfo('sex')"></i>
        </span>
        <span v-else>
           <span  style="width: 20vw; display:inline-block">
              <el-radio v-model="form.sexId" label="1">男</el-radio>
              <el-radio v-model="form.sexId" label="2">女</el-radio>
           </span>
          <span class="save-input-suffix" @click="saveOnClick('sex')">保存</span>
          <span class="cancel-input-suffix" @click="cancelOnClick('sex')">取消</span>
        </span>
      </el-form-item>
    </el-form>

    <el-dialog
      title="修改密码"
      :visible.sync="showPasswordModalStatus"
      width="30%"
      :close-on-click-modal="false"
    >
      <div><span><span style="color: red">*</span> 输入新密码：</span><el-input clearable v-model="newPassword" show-password style="width: 20vw"></el-input></div><br>
      <div><span><span style="color: red">*</span> 确认新密码：</span><el-input clearable v-model="newPassword2" show-password style="width: 20vw"></el-input></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">取 消</el-button>
        <el-button type="primary" @click="updateUserPassword">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改手机号"
      :visible.sync="showPhoneNumberModalStatus"
      width="30%"
      :close-on-click-modal="false"
    >
      <div><span><span style="color: red">*</span> 新手机号：</span><el-input clearable v-model="newPhoneNumber" style="width: 20vw"></el-input></div><br>
      <div><span><span style="color: red">*</span> 验证码：</span>
        <el-input v-model="phoneCode" style="width: 10vw; padding-left: 13px" clearable></el-input>
        <span style="padding-left: 20px; color: #2281FF" @click="sendPhoneCode">
          {{
            countDown === '获取短信验证码'
              ? countDown
              : countDown + '秒后重试'
          }}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">取 消</el-button>
        <el-button type="primary" @click="saveOnClick('phoneNumber')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改邮箱"
      :visible.sync="showEmailModalStatus"
      width="30%"
      :close-on-click-modal="false"
    >
      <div><span><span style="color: red">*</span> 新邮箱：</span><el-input clearable v-model="newEmail" style="width: 20vw"></el-input></div><br>
      <div><span><span style="color: red">*</span> 验证码：</span>
        <el-input v-model="emailCode" style="width: 10vw" clearable></el-input>
        <span style="padding-left: 20px; color: #2281FF" @click="sendMailCode">
          {{
            countMailDown === '获取邮箱验证码'
              ? countMailDown
              : countMailDown + '秒后重试'
          }}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">取 消</el-button>
        <el-button type="primary" @click="saveOnClick('email')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {MessageBox} from "element-ui";
import router from "@/router";
import store from "@/store";

export default {
  name: 'personalCenterMainView',
  data() {
    return {
      form: {},
      showEditLoginNameStatus: false,
      showEditPasswordStatus: false,
      showEditUserNameStatus: false,
      showEditEmailStatus: false,
      showEditSexStatus: false,
      showPasswordModalStatus: false,
      showPhoneNumberModalStatus: false,
      showEmailModalStatus: false,
      newPassword: '',
      newPassword2: '',
      newPhoneNumber: '',
      newEmail: '',
      phoneCode: '',
      emailCode: '',
      countDown: '获取短信验证码',
      countMailDown: '获取邮箱验证码',
      countDownInterval: '',
      countMailDownInterval: '',
    };
  },
  mounted() {
    this.$api.personalCenter.queryCurrentUserInfo().then((res) => {
      if (res.code === 0) {
        this.form = res.data;
      }
    });
  },
  methods: {
    editUserInfo(field) {
      if (field === 'userName') {
        this.showEditUserNameStatus = true
      } else if (field === 'sex') {
        this.showEditSexStatus = true
      }
    },
    cancelOnClick(field) {
      if (field === 'loginName') {
        this.showEditLoginNameStatus = false;
      } else if (field === 'password') {
        this.showEditPasswordStatus = false;
        this.newPassword = '';
        this.newPassword2 = '';
      } else if (field === 'userName') {
        this.showEditUserNameStatus = false
      } else if (field === 'email') {
        this.showEditEmailStatus = false;
        this.phoneCode = '';
      } else if (field === 'sex') {
        this.showEditSexStatus = false
      }

      this.$api.personalCenter.queryCurrentUserInfo().then((res) => {
        if (res.code === 0) {
          this.form = res.data;
        }
      });
    },
    closeModal() {
      this.showPasswordModalStatus = false;
      this.showPhoneNumberModalStatus = false;
      this.showEmailModalStatus = false;
      this.newPassword = '';
      this.newPassword2 = '';
      this.newPhoneNumber = '';
      this.newEmail = '';
      this.phoneCode = '';
      this.emailCode = '';
    },
    updateUserPassword() {
      if (!this.newPassword || !this.newPassword2) {
        this.$message({message: '请输入新密码', type: 'error'});
        return ;
      }

      if (this.newPassword !== this.newPassword2) {
        this.$message({message: '两次密码输入不一致', type: 'error'});
        return ;
      }

      this.form.password = this.newPassword;
      this.$api.personalCenter.updateUserPassword(this.form).then((res) => {
        if (res.code === 0) {
          this.$message({message: '修改成功', type: 'success'});
          this.showPasswordModalStatus = false;

          setTimeout(() => {
            MessageBox.alert('登录过期，请重新登录!', {
              title: '提示',
              confirmButtonText: '确定',
              showClose: false,
              type: 'warning',
            }).then(() => {
              let loginPath = '';
              let userInfo = JSON.parse(
                window.sessionStorage.getItem('userInfo')
              );
              loginPath = userInfo.fullPath;
              window.sessionStorage.removeItem('userInfo');
              // 退出成功后关闭所有tag
              this.$store.dispatch('tagsView/delAllViews');
              this.$router.push({ path: loginPath });
            });
          }, 500);
        }
      });
    },
    saveOnClick(field) {
      if (field === 'userName') {
        if (!this.form.userName) {
          this.$message({message: '请输入姓名', type: 'error'});
          return ;
        }
      }

      if (field === 'phoneNumber') {
        if (!this.newPhoneNumber) {
          this.$message({message: '请输入新手机号', type: 'error'});
          return ;
        }

        let phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

        if (!phoneReg.test(this.newPhoneNumber)) {
          this.$message({message: '手机号格式错误', type: 'error'});
          return ;
        }

        if (!this.phoneCode) {
          this.$message({message: '请输入短信验证码', type: 'error'});
          return ;
        }

        this.form.phonenumber = this.newPhoneNumber;
        this.form.newPhoneNumber = this.newPhoneNumber;
        this.form.phoneCode = this.phoneCode;
      } else if (field === 'email') {
        let emailReg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;

        if (!this.newEmail) {
          this.$message({message: '请输入新邮箱', type: 'error'});
          return ;
        }

        if (!emailReg.test(this.newEmail)) {
          this.$message({message: '邮箱格式错误', type: 'error'});
          return ;
        }

        if (!this.emailCode) {
          this.$message({message: '请输入邮箱验证码', type: 'error'});
          return ;
        }

        this.form.email = this.newEmail;
        this.form.newEmail = this.newEmail;
        this.form.emailCode = this.emailCode;
      } else if (field === 'sex') {
        this.form.sex = this.form.sexId === '1' ? '男' : '女'
      }

      this.$api.personalCenter.saveUserInfo(this.form).then((res) => {
        if (res.code === 0) {
          this.form = res.data;
          this.showEditLoginNameStatus = false;
          this.showEditPasswordStatus = false;
          this.showEditUserNameStatus = false;
          this.showEditEmailStatus = false;
          this.showEditSexStatus = false;
          this.showPasswordModalStatus = false;
          this.showPhoneNumberModalStatus = false;
          this.showEmailModalStatus = false;

          this.$message({message: '修改成功', type: 'success'});

          // 用户名  密码  姓名  手机号  邮箱  修改了就让退出重新登录
          if (field !== 'sex') {
            setTimeout(() => {
              MessageBox.alert('登录过期，请重新登录!', {
                title: '提示',
                confirmButtonText: '确定',
                showClose: false,
                type: 'warning',
              }).then(() => {
                let loginPath = '';
                let userInfo = JSON.parse(
                  window.sessionStorage.getItem('userInfo')
                );
                loginPath = userInfo.fullPath;
                window.sessionStorage.removeItem('userInfo');
                window.sessionStorage.removeItem('orderCode');
                // 退出成功后关闭所有tag
                this.$store.dispatch('tagsView/delAllViews');
                this.$router.push({ path: loginPath });
              });
            }, 500);
          }
        } else {
          this.$api.personalCenter.queryCurrentUserInfo().then((res) => {
            if (res.code === 0) {
              this.form = res.data;
            }
          });
        }
      });
    },
    openUserInfoModal(field) {
      if (field === 'password') {
        this.showPasswordModalStatus = true;
        this.showEditSexStatus = false
      } else if (field === 'phoneNumber') {
        this.showPhoneNumberModalStatus = true;
      } else if (field === 'email') {
        this.showEmailModalStatus = true;
      }

      this.showEditUserNameStatus = false;
      this.showEditSexStatus = false
    },
    sendPhoneCode() {
      let newPhoneNumber = this.newPhoneNumber;

      if (!newPhoneNumber) {
        this.$message({message: '请输入新手机号', type: 'error'});
        return
      }

      if (!/^1(3|4|5|7|8)\d{9}$/.test(newPhoneNumber)) {
        this.$message.error('请输入正确的手机号');
        return;
      }

      if (this.countDown !== '获取短信验证码') {
        this.$message.error('请在' + this.countDown + '秒后重试');
        return;
      }

      this.countDown = 60;
      this.$api.personalCenter.sendPhoneCode({phoneNumber: newPhoneNumber}).then((res) => {
        this.countDownInterval = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            this.countDown = '获取短信验证码';
            clearInterval(this.countDownInterval);
          }
        }, 1000);
        if (res.code === 0) {
          this.$message.success('发送成功');
        }
      });
    },

    sendMailCode() {
      let newEmail = this.newEmail;
      let emailReg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;

      if (!newEmail) {
        this.$message({message: '请输入新邮箱', type: 'error'});
        return ;
      }

      if (!emailReg.test(newEmail)) {
        this.$message({message: '邮箱格式错误', type: 'error'});
        return ;
      }

      if (this.countMailDown !== '获取邮箱验证码') {
        this.$message.error('请在' + this.countMailDown + '秒后重试');
        return;
      }

      this.countMailDown = 60;
      this.$api.personalCenter.sendEMailCode({newEmail: newEmail}).then((res) => {
        this.countMailDownInterval = setInterval(() => {
          if (this.countMailDown > 0) {
            this.countMailDown--;
          } else {
            this.countMailDown = '获取邮箱验证码';
            clearInterval(this.countMailDownInterval);
          }
        }, 1000);
        if (res.code === 0) {
          this.$message.success('发送成功');
        }
      });
    }

  }
};
</script>

<style lang="scss" scoped>
.form-box {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background: #fff;
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 150px;

  .form-content {
    width: 60%;
    margin: 0 auto;
    margin-top: 30px;

    .el-input {
      width: 400px;
    }

    .el-icon-edit {
      color: #2281FF;
      margin-left: 10px;
    }

    .save-input-suffix {
      color: #2281FF;
      margin-left: 10px;
    }

    .cancel-input-suffix {
      margin-left: 10px;
    }
  }

  .form-title {
    line-height: 40px;
    border-bottom: 2px solid #e5e5e5;
    padding-left: 10px;
    font-weight: bold;
    font-size: 18px;
  }
  .el-cascader {
    width: 100%;
  }
}

</style>
